var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer", attrs: { id: "footer" } }, [
    _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c(
        "p",
        [
          _vm._v("Made with "),
          _c("font-awesome-icon", {
            staticStyle: { color: "#d8165a" },
            attrs: { icon: "heart" },
          }),
          _vm._v(" using PyHipster"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }