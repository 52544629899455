import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Polygon = () => import('@/entities/polygon/polygon.vue');
// prettier-ignore
const PolygonUpdate = () => import('@/entities/polygon/polygon-update.vue');
// prettier-ignore
const PolygonDetails = () => import('@/entities/polygon/polygon-details.vue');
// prettier-ignore
const Measure = () => import('@/entities/measure/measure.vue');
// prettier-ignore
const MeasureUpdate = () => import('@/entities/measure/measure-update.vue');
// prettier-ignore
const MeasureDetails = () => import('@/entities/measure/measure-details.vue');
// prettier-ignore
const Process = () => import('@/entities/process/process.vue');
// prettier-ignore
const ProcessUpdate = () => import('@/entities/process/process-update.vue');
// prettier-ignore
const ProcessDetails = () => import('@/entities/process/process-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'polygon',
      name: 'Polygon',
      component: Polygon,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'polygon/new',
      name: 'PolygonCreate',
      component: PolygonUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'polygon/:polygonId/edit',
      name: 'PolygonEdit',
      component: PolygonUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'polygon/:polygonId/view',
      name: 'PolygonView',
      component: PolygonDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'measure',
      name: 'Measure',
      component: Measure,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'measure/new',
      name: 'MeasureCreate',
      component: MeasureUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'measure/:measureId/edit',
      name: 'MeasureEdit',
      component: MeasureUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'measure/:measureId/view',
      name: 'MeasureView',
      component: MeasureDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'process',
      name: 'Process',
      component: Process,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'process/new',
      name: 'ProcessCreate',
      component: ProcessUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'process/:processId/edit',
      name: 'ProcessEdit',
      component: ProcessUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'process/:processId/view',
      name: 'ProcessView',
      component: ProcessDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
